<template>
  <v-container fluid class="full-fill pa-0">
    <v-row class="full-fill">
      <!-- Search -->
      <v-col class="col-12 col-sm-3 ma-0 pa-5">
        <Search v-on:updateSearch="onUpdateSearch"/>
      </v-col>
      <!-- Content -->
      <v-col align="center" class="col-sm-7 ma-0 pa-5 pl-5 pr-5 pl-sm-0 pr-sm-0">
        <v-row class="full-fill">
          <v-col align="center" class="ma-0 pa-0">
            <StoryThumbnail v-for="result in results" :key="result.key" :story="result" :storyID="result.id"/>
            <v-card v-if="!results || results.length===0" color="grey lighten-3" class="pa-5">
              <strong>No results.</strong>
              <br/>
              <br/>
              Know a veteran who matches your search? <router-link to="story-create">Create their story.</router-link>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Ads -->
      <v-col class="col-3 d-none d-sm-flex">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Search from '@/components/Search'
  import StoryThumbnail from '@/components/StoryThumbnail'

  export default {
    name: 'SearchResults',

    props: ['results'],

    data () {
      return {
        updateSearch: null
      }
    },

    components: {
      Search,
      StoryThumbnail
    },

    created: function () {
      this.onUpdateSearch(this.results)
    },

    methods: {
      onUpdateSearch (results) {
        this.results = results
      }
    }
  }
</script>

<style scoped>
  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>
