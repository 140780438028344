<template>
  <v-container fluid>
    <router-link :to="{ path: 'story-view', query: { storyID: storyID } }" class="black--text" style="text-decoration: none;">
      <v-card class="ma-0 pa-0">
        <v-img
          class="align-center pl-5 pl-sm-10 white--text"
          height="250px"
          src="@/assets/img/bg-story-thumbnail.png"
        >
          <v-card-title align="left" class="ma-0 pa-0 pb-2 text-h4" style="word-break: keep-all">{{ story.firstName }} {{ story.middleName }} {{ story.nickname ? ('"' + story.nickname + '"') : '' }} {{ story.lastName }}</v-card-title>
          <v-card-subtitle align="left" class="ma-0 pa-0 pb-5 white--text">
            <div v-if="story.serviceTerms && story.serviceTerms[0].rank">{{ story.serviceTerms[0].rank ? story.serviceTerms[0].rank.text : '' }}</div>
            {{ story.serviceTerms && story.serviceTerms[0].branch ? story.serviceTerms[0].branch.text : '' }}
          </v-card-subtitle>
          <v-card-actions class="ma-0 pa-0">
            <v-img src="@/assets/img/reaction-flag-l-white.png" max-width="58" max-height="32" class="mr-5"></v-img>
            <v-img src="@/assets/img/reaction-cake-l-white.png" max-width="32" max-height="32"></v-img>
          </v-card-actions>
        </v-img>
      </v-card>
    </router-link>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'StoryThumbnail',

    props: ['story', 'storyID'],

    computed: {
      ...mapGetters([
        'isAuth'
      ]),
      isBirthdayToday: function () {
        if (!this.story.dob) return false

        const birthMonth = this.story.dob.substring(5, 7)
        const birthDay = this.story.dob.substring(8, 10)
        const todaysDate = new Date().toISOString().split('T')[0]
        const month = todaysDate.substring(5, 7)
        const day = todaysDate.substring(8, 10)

        return (birthMonth === month && birthDay === day)
      }
    }
  }
</script>

<style scoped>

</style>
